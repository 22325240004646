import { Injectable } from '@angular/core';

//import { ApmService } from '@elastic/apm-rum-angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Angulartics2 } from 'angulartics2';
import { v4 as uuidv4 } from 'uuid';
import { InteractionEvent } from '../models/interaction-event.model';
import { Angulartics2Tracker } from '../models/tracking/angulartics2-tracker';
import { GoogleTagTracker } from '../models/tracking/google-tag-tracker';

import { IntercomTracker } from '../models/tracking/intercom-tracker';
import { TrackingService } from '../models/tracking/tracking-service.interface';
import { UserModel } from '../proto/common-message.pb';
import { AuthService } from './portal/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInteractionService {
  private currentUser: UserModel | undefined;
  private trackingServices: TrackingService[] = [];
  private firstEmissionHandled = false;
  constructor(
    private authService: AuthService,
    //private apmService: ApmService,
    private angulartics2: Angulartics2,
    private gtmService: GoogleTagManagerService,
  ) {
    // Inicializar los servicios de seguimiento
    this.trackingServices.push(new IntercomTracker());
    //  this.trackingServices.push(new ApmTracker(apmService));
    this.trackingServices.push(new Angulartics2Tracker(angulartics2));
    this.trackingServices.push(new GoogleTagTracker(gtmService));
    this.authService.userSubject$.subscribe(user => {
      if (user === undefined) {
        if (this.firstEmissionHandled) this.emitLogoutEvent();
        this.firstEmissionHandled = false;
        return;
      }
      //
      if (!this.firstEmissionHandled) {
        this.emitOpenAppEvent(user);
        this.firstEmissionHandled = true;
      }
      this.currentUser = user;
    });
  }

  /**
   * Emits an interaction event and sends it to Intercom and Elastic APM.
   *
   * @param eventData - The data for the interaction event.
   */
  emit(eventData: Omit<InteractionEvent, 'uuid' | 'created' | 'device'>) {
    // Garantizar que el usuario que ingresa no sea manager o lo sea de si mismo
    if (this.currentUser?.manager && this.currentUser?.manager?.id !== this.currentUser?.id) return;

    // Crear el evento
    const event = this.createInteractionEvent(eventData);
    try {
      console.log(`event :${event.uuid} sent`, event);
      // Enviar el evento a los servicios de seguimiento
      this.trackingServices.forEach(service => service.trackEvent(event));
    } catch (error) {
      console.error('Error in AppInteractionService:', error);
      //  this.apmService.apm.captureError(error as any);
    }
  }

  /**
   * Creates an interaction event based on the provided eventData.
   * @param eventData - The data for the interaction event.
   * @returns The created interaction event.
   */
  private createInteractionEvent(
    eventData: Omit<InteractionEvent, 'uuid' | 'created' | 'device'>,
  ): InteractionEvent {
    return {
      ...eventData,
      userId: eventData.userId || this.currentUser?.id,
      email: eventData.email || this.currentUser?.email,
      uuid: uuidv4(),
      device: navigator.userAgent,
      created: new Date().toLocaleDateString(),
    };
  }
  private emitLogoutEvent() {
    if (
      // Garantizar que el usuario que ingresa no sea manager o lo sea de si mismo
      this.currentUser?.manager &&
      this.currentUser?.manager?.id !== this.currentUser?.id
    )
      return;
    // Emitir el evento
    this.emit({
      eventName: 'userLogout',
      eventDetail: 'El usuario ha cerrado sesión',
    });
  }

  private emitOpenAppEvent(user: UserModel) {
    if (
      // Garantizar que el usuario que ingresa no sea manager o lo sea de si mismo
      user.manager &&
      user.manager?.id !== user.id
    )
      return;
    // Emitir el evento
    this.emit({
      eventName: 'userOpenApp',
      eventDetail: 'El usuario ha abierto o tiene actividad en el portal',
      userId: user.id,
    });
  }
}
